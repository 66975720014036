import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () =>
{
	const location = useLocation();

	useEffect(() =>
	{
		const hash = location.hash;
		if ( hash )
		{
			const targetElement = document.querySelector(hash);

			if ( targetElement )
			{
				targetElement.scrollIntoView({
					behavior: "smooth",
					block: "start",
					inline: "nearest",
				});
			}
		}
	}, [ location ]);

	return null;
};

export default ScrollToHashElement;
