import React from "react";
import { Router } from "./routing/Router";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getAnalytics } from "firebase/analytics";

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return <Router />;
}

export default App;
