import { FC } from "react";
import styles from "./Button.module.sass";
import classNames from "classnames/dedupe";
import { TIBOX_LINK } from "../../constants/links";
import ReactPixel from "react-facebook-pixel";

export enum ButtonType {
  Big = "big",
  Medium = "medium",
  Small = "small",
  GreenMedium = "greenMedium",
}

interface IButtonProps {
  size?: ButtonType;
  name?: string;
  link?: string;
  className?: string;
  target?: string;
  id?: string;
}

export const Button: FC<IButtonProps> = ({
  size = ButtonType.Big,
  name = "Придбати квиток",
  link = TIBOX_LINK,
  className = "",
  ...restProps
}) => {
  const getClassNameBySize: Record<ButtonType, string> = {
    [ButtonType.Big]: styles.big,
    [ButtonType.Medium]: styles.medium,
    [ButtonType.Small]: styles.small,
    [ButtonType.GreenMedium]: styles.mediumGreen,
  };

  const classBySize = getClassNameBySize[size];

  ReactPixel.init("995421881575464");

  const handleClick = () => {
    if (link === TIBOX_LINK) {
      ReactPixel.trackCustom("ClickByButton");
    }
  };

  return (
    <a
      onClick={handleClick}
      className={classNames("df-center", styles.button, classBySize, className)}
      href={link}
      target={link === TIBOX_LINK ? "_blank" : ""}
      rel="noreferrer"
      {...restProps}
    >
      {name}
    </a>
  );
};
