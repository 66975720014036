import { FC, ReactNode } from "react";
import classNames from "classnames/dedupe";
import styles from "./Container.module.sass";

interface IContainerProps {
  className?: string;
  id?: string;
  children: ReactNode;
}

export const Container: FC<IContainerProps> = ({
  className = "",
  id = "",
  children,
}) => {
  const classList = classNames(styles.container, className);
  return (
    <div id={id} className={classList}>
      {children}
    </div>
  );
};
