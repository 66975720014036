import { FC } from "react";
import styles from "./Title.module.sass";
import classNames from "classnames/dedupe";

interface ITitleProps {
  className?: string;
  text: string;
}

export const Title: FC<ITitleProps> = ({ className, text }) => {
  return <h2 className={classNames(styles.title, className)}>{text}</h2>;
};
