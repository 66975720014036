import { FC } from "react";
import styles from "./Promotion.module.sass";
import { Container } from "../../../../components/Container";
import LogoPag from "../../../../assets/img/PabLogoShort.svg";
import classNames from "classnames/dedupe";
import Sphere from "../../../../assets/img/ui/sphere_orange.png";

export const Promotion: FC = () => {
  return (
      <div className={styles.root}>
        <img className={styles.sphere} src={Sphere} alt={"Levitation dot"}/>
        <Container className={classNames("df-between", styles.container)}>
          <div className={styles.logo}>
            <img className="img" src={LogoPag} alt="img: Pab Logo"/>
          </div>
          <h3 className={styles.text}>
            Ми, як бізнес-спільнота, об’єдналися заради розвитку власної справи та
            суспільства, щоб створити комфортне середовище навколо себе заради
            нової України, починаючи з Полтави.
          </h3>
        </Container>
      </div>
  );
};
