import { FC } from "react";
import styles from "./Info.module.sass";
import BorderImage from "../../../../assets/img/border.svg";
import { Container } from "../../../../components/Container";
import classNames from "classnames/dedupe";
import { Logo, LogoSize } from "../../../../components/Logo";

export const Info: FC = () => {
  return (
    <Container
      className={classNames("df-center fd-column", styles.root)}
      id="forumInfo"
    >
      <div data-aos="fade-down" className={classNames("df-center", styles.quote)}>
        <img className={styles.border} src={BorderImage} alt={"border"} />
        <p className={styles.quoteText}>
          Розширюй горизонти свого бізнесу. Відкривай нові можливості на <br />
          PRO Синтез бізнес.
        </p>
      </div>
      <div className={classNames(styles.content)}>
        <div className={classNames("df-center", styles.card, styles.card1)}>
          <p>
            PRO СИНТЕЗ бізнес – це потужна платформа для взаємодії, об’єднання
            та розвитку бізнесу.
          </p>
        </div>
        <div
          className={classNames(
            "df-start fd-column",
            styles.card,
            styles.cardBottom,
            styles.card2,
          )}
        >
          <p>
            Кожен бізнес особливий. Кожен досвід унікальний. Кожна думка
            важлива. Синтезуємо знання разом.
          </p>
        </div>
        <Logo className={styles.logo} color={true} size={LogoSize.big} />
        <div
          className={classNames(
            "df-center",
            styles.card,
            styles.cardRight,
            styles.card3,
          )}
        >
          <p>
            Форум – місце для синтезу прогресивних ідей та пошуку можливостей
            для зростання.
          </p>
        </div>
        <div
          className={classNames(
            "df-center",
            styles.card,
            styles.cardRight,
            styles.cardBottom,
            styles.card4,
          )}
        >
          <p>
            Форум об’єднує підприємців, експертів та лідерів думок для обміну
            досвідом та пошуку інновацій.
          </p>
        </div>
      </div>
    </Container>
  );
};
