import { FC } from "react";
import LogoIcon from "../../assets/img/logo.svg";
import LogoColorIcon from "../../assets/img/logoColor.svg";
import classNames from "classnames/dedupe";
import styles from "./Logo.module.sass";

export enum LogoSize {
  big = "big",
  medium = "medium",
  small = "small",
  extraSmall = "extraSmall",
}

interface ILogoProps {
  className?: string;
  color?: boolean;
  size?: LogoSize;
}

export const Logo: FC<ILogoProps> = ({
  size = LogoSize.small,
  color = false,
  className = "",
}) => {
  const getClassBySize: Record<LogoSize, string> = {
    [LogoSize.big]: styles.big,
    [LogoSize.medium]: styles.medium,
    [LogoSize.small]: styles.small,
    [LogoSize.extraSmall]: styles.extraSmall,
  };

  const classBySize = getClassBySize[size];

  return (
    <img
      className={classNames(classBySize, className)}
      src={color ? LogoColorIcon : LogoIcon}
      alt="Logo: ProSyntez"
    />
  );
};
