import { FC } from "react";
import { Container } from "../../../../components/Container";
import styles from "./Workshop.module.sass";
import { Title } from "../../../../components/Title";
import Speakers1 from "../../../../assets/img/spikers/workshop1.png";
import Speaker2 from "../../../../assets/img/spikers/workshop2.png";
import classNames from "classnames/dedupe";

export const Workshop: FC = () => {
  return (
    <div className={styles.r}>
      <div className={styles.darkDot}></div>
      <Container className={classNames("df-center fd-column", styles.root)}>
        <div className={classNames("df-center", styles.header)}>
          <Title text="ВОРКШОПи" />
        </div>
        <div className={classNames("df-center fd-column", styles.container)}>
          <div className={classNames("df-center", styles.row, styles.rowLine)}>
            <div className={classNames("df-center fd-column", styles.head)}>
              <div className={classNames("df-center", styles.times)}>
                <p className={classNames("df-center fd-column", styles.column)}>
                  <span className={styles.timeText}>Початок</span>
                  <strong className={styles.time}>12:00</strong>
                </p>
                <hr className={styles.line} />
                <p
                  className={classNames(
                    "df-center",
                    styles.column,
                    styles.columnRev,
                  )}
                >
                  <strong className={styles.time}>13:00</strong>
                  <span className={styles.timeText}>завершення</span>
                </p>
              </div>
              <div className={styles.imgWr}>
                <img
                  className={styles.img}
                  src={Speakers1}
                  alt={"Speakers Image"}
                />
              </div>
            </div>
            <div className={classNames("df-center fd-column", styles.content)}>
              <h3 className={styles.title}>
                Віталій Дмитрієв{"\n"}
                <span>та{"\n"}</span> Ганна Приходченко
              </h3>
              <p className={styles.text}>
                Людський капітал як найцінніший ресурс бізнесу. Як адаптуватися
                до нових реалій ринку праці та залишатися конкурентоспроможним у
                сучасних умовах.
              </p>
            </div>
          </div>
          <div className={classNames("df-center", styles.row)}>
            <div className={classNames("df-center fd-column", styles.head)}>
              <div className={classNames("df-center", styles.times)}>
                <p className={classNames("df-center fd-column", styles.column)}>
                  <span className={styles.timeText}>Початок</span>
                  <strong className={styles.time}>15:30</strong>
                </p>
                <hr className={styles.line} />
                <p
                  className={classNames(
                    "df-center",
                    styles.column,
                    styles.columnRev,
                  )}
                >
                  <strong className={styles.time}>16:30</strong>
                  <span className={styles.timeText}>завершення</span>
                </p>
              </div>
              <div className={classNames(styles.imgWr, styles.imgWr2)}>
                <img
                  className={styles.img}
                  src={Speaker2}
                  alt={"Speaker Image"}
                />
              </div>
            </div>
            <div className={classNames("df-center fd-column", styles.content)}>
              <h3 className={styles.title}>Олександр Колесніков</h3>
              <p className={styles.text}>
                Майбутнє бізнесу: Автоматизація за допомогою штучного інтелекту
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
