import { FC } from "react";
import styles from "./Tickets.module.sass";
import classNames from "classnames/dedupe";
import { Button } from "../../../../components/Button";
import { Container } from "../../../../components/Container";
import GreenDot from "../../../../assets/img/list/green_dot.svg";
import BlueDot from "../../../../assets/img/list/blue_dot.svg";
import OrangeDot from "../../../../assets/img/list/orange_dot.svg";

export const Tickets: FC = () => {
  return (
    <Container id="tickets" className={classNames(styles.root)}>
      <div
        className={classNames(
          "df-center fd-column",
          styles.ticket,
          styles.ticket1,
        )}
      >
        <img className={styles.dot} src={GreenDot} alt="green dot" />
        <div className={styles.container}>
          <div className={styles.header}>
            <h3 className={styles.title}>STANDART</h3>
            <p className={styles.price}>2 500₴</p>
          </div>
          <div className={styles.content}>
            <ul className={styles.list}>
              <li className={styles.li}>Участь у форумі</li>
              <li className={styles.li}>Участь у воркшопах</li>
              <li className={styles.li}>Кава-брейки</li>
              <li className={styles.li}>
                Участь у <strong>розіграшах</strong> від партнерів
              </li>
              <li className={styles.li}>Доступ до чату форуму</li>
              <li className={styles.li}>Відеозапис виступів</li>
              <li className={styles.li}>
                Місце на вибір в секторі <strong>STANDART</strong>
              </li>
              <li className={styles.li}>After Party</li>
            </ul>
          </div>
        </div>
        <Button className={styles.button} />
      </div>
      <div
        className={classNames(
          "df-center fd-column",
          styles.ticket,
          styles.ticket2,
        )}
      >
        <img className={styles.dot} src={BlueDot} alt="blue dot" />
        <div className={styles.container}>
          <div className={styles.header}>
            <h3 className={styles.title}>STANDART +</h3>
            <p className={styles.price}>3 000₴</p>
          </div>
          <div className={styles.content}>
            <ul className={styles.list}>
              <li className={styles.li}>Участь у форумі</li>
              <li className={styles.li}>Участь у воркшопах</li>
              <li className={styles.li}>Кава-брейки</li>
              <li className={styles.li}>
                Участь у <strong>розіграшах</strong> від партнерів
              </li>
              <li className={styles.li}>Доступ до чату форуму</li>
              <li className={styles.li}>Відеозапис виступів</li>
              <li className={styles.li}>
                Місце в секторі <strong>STANDART +</strong>
              </li>
              <li className={styles.li}>After Party</li>
            </ul>
          </div>
        </div>
        <Button className={styles.button} />
      </div>
      <div
        className={classNames(
          "df-center fd-column",
          styles.ticket,
          styles.ticket3,
        )}
      >
        <img className={styles.dot} src={OrangeDot} alt="orange dot" />
        <div className={styles.container}>
          <div className={styles.header}>
            <h3 className={styles.title}>BUSINESS</h3>
            <p className={styles.price}>3 500₴</p>
          </div>
          <div className={styles.content}>
            <ul className={styles.list}>
              <li className={styles.li}>Участь у форумі</li>
              <li className={styles.li}>Участь у воркшопах</li>
              <li className={styles.li}>Кава-брейки</li>
              <li className={styles.li}>
                Участь у <strong>розіграшах</strong> від партнерів
              </li>
              <li className={styles.li}>Доступ до чату форуму</li>
              <li className={styles.li}>Відеозапис виступів</li>
              <li className={styles.li}>
                Місце в секторі <strong>BUSINESS</strong>
              </li>
              <li className={styles.li}>After Party</li>
              <li className={styles.li}>Вечеря зі спікерами</li>
            </ul>
          </div>
        </div>
        <Button className={styles.button} />
      </div>
      <div className={styles.darkDot}></div>
      <div className={styles.lightDot}></div>
    </Container>
  );
};
