import { FC } from "react";
import styles from "./Numbers.module.sass";
import { Container } from "../../../../components/Container";
import classNames from "classnames/dedupe";
import Sphere from "../../../../assets/img/ui/sphere_orange.png";

export const Numbers: FC = () => {
  return (
      <div className={classNames("df-center fd-column", styles.root)}>
        <div className={styles.darkDot}></div>
        <img className={styles.sphere} src={Sphere} alt={"Levitation dot"}/>
        <h2 className={styles.header}>
          Ми провели 2 бізнес-форуми <br/>й готуємо новий. За цей час маємо:
        </h2>
        <Container className={styles.container}>
          <div className={classNames(styles.card, styles.card1, styles.cardH1)}>
            <p className={classNames(styles.title, styles.title1)}>75%</p>
            <div
                data-aos="fade-right"
                className={classNames("df-center", styles.tag, styles.tag1)}
            >
              учасників є підприємцями
            </div>
          </div>
          <div className={classNames(styles.card, styles.card2, styles.cardH1)}>
            <p className={styles.title}>500+</p>
            <div
                data-aos="fade-left"
                className={classNames("df-center", styles.tag, styles.tag2)}
            >
              учасників форуму
            </div>
          </div>
          <div className={classNames(styles.card, styles.card3)}>
            <p className={styles.title}>20+</p>
            <div
                data-aos="fade-right"
                className={classNames("df-center", styles.tag, styles.tag3)}
            >
              спікерів форуму
            </div>
          </div>
          <div className={classNames(styles.card, styles.card4)}>
            <p className={styles.title}>20+</p>
            <div
                data-aos="fade-left"
                className={classNames("df-center", styles.tag, styles.tag4)}
            >
              годин та обговорень
            </div>
          </div>
        </Container>
      </div>
  );
};
