import { useState, useEffect, FC } from "react";
import { Container } from "../../../../components/Container";
import styles from "./Timer.module.sass";
import classNames from "classnames/dedupe";
import Sphere from "../../../../assets/img/ui/sphere_green.png";

interface ITimerProps {
  targetDate: string;
}

export const Timer: FC<ITimerProps> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = new Date(targetDate).getTime() - now.getTime();

    if (difference <= 0) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      return;
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    setTimeLeft({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  useEffect(() => {
    calculateTimeLeft();
    const id = setInterval(calculateTimeLeft, 1000);
    setIntervalId(id);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const { days, hours, minutes, seconds } = timeLeft;
  const formatTime = (num: number) => (num < 10 ? `0${num}` : num);

  return (
    <div className={styles.root}>
      <img className={styles.sphere} src={Sphere} alt={"Levitation dot"} />
      <Container className={classNames("df-between", styles.container)}>
        <p className={styles.text}>До форуму залишилося:</p>
        <div className="df-start">
          <div className="df-between fd-column">
            <strong className={styles.time}>{formatTime(days)}</strong>
            <span className={styles.subText}>днів</span>
          </div>
          <strong className={styles.dividerTime}>:</strong>
          <div className="df-between fd-column">
            <strong className={styles.time}>{formatTime(hours)}</strong>
            <span className={styles.subText}>годин</span>
          </div>
          <strong className={styles.dividerTime}>:</strong>
          <div className="df-between fd-column">
            <strong className={styles.time}>{formatTime(minutes)}</strong>
            <span className={styles.subText}>хвилин</span>
          </div>
          <strong className={styles.dividerTime}>:</strong>
          <div className="df-between fd-column">
            <strong className={styles.time}>{formatTime(seconds)}</strong>
            <span className={styles.subText}>секунд</span>
          </div>
        </div>
      </Container>
    </div>
  );
};
