import { Container } from "../../../../components/Container";
import classNames from "classnames/dedupe";
import PabLogo from "../../../../assets/img/partners/PabLogo.svg";
import UkrSibBank from "../../../../assets/img/partners/urkSibBank.svg";
import AvroraLogo from "../../../../assets/img/partners/avroraLogo.svg";
import Autotrans from "../../../../assets/img/partners/autotransLogo.svg";
import Yavir from "../../../../assets/img/partners/yavirLogo.svg";
import EPilot from "../../../../assets/img/partners/epilotLogo.svg";
import GreenEl from "../../../../assets/img/partners/greenSlonLogo.svg";
import Konsort from "../../../../assets/img/partners/konsortLogo.svg";
import ImigM from "../../../../assets/img/partners/imigLogo.svg";
import TorgService from "../../../../assets/img/partners/torgservisLogo.svg";
import K from "../../../../assets/img/partners/kLogo.svg";
import Ende from "../../../../assets/img/partners/endevourLogo.svg";
import Fibra from "../../../../assets/img/partners/fiber.svg";
import PremierSocks from "../../../../assets/img/partners/premierSocks.svg";
import PalitraMix from "../../../../assets/img/partners/palitraMix.svg";
import RostTreid from "../../../../assets/img/partners/rostTreid.svg";
import Digicode from "../../../../assets/img/partners/digicodeLogo.svg";
import R9 from "../../../../assets/img/partners/r9.svg";
import Cinno from "../../../../assets/img/partners/cinno.svg";
import FoodGo from "../../../../assets/img/partners/food_go.svg";
import MM from "../../../../assets/img/partners/mm.svg";
import TG from "../../../../assets/img/partners/tg.svg";
import Shieds from "../../../../assets/img/shields.png";
import WorldWine from "../../../../assets/img/partners/worldWine.svg";
import Premier from "../../../../assets/img/partners/premier.svg";
import RadioSound from "../../../../assets/img/partners/radioSound.svg";
import styles from "./Partners.module.sass";
import Sphere from "../../../../assets/img/ui/sphere_green.png";
import { Title } from "../../../../components/Title";

export const Partners = () => {
  const partnersList = [
    { img: AvroraLogo },
    { img: Digicode },
    { img: Autotrans },
    { img: Yavir },
    { img: EPilot },
    { img: GreenEl },
    { img: Konsort },
    { img: ImigM },
    { img: TorgService },
    { img: K },
    { img: Ende },
    { img: Fibra },
    { img: PremierSocks },
    { img: PalitraMix },
    { img: RostTreid },
  ];

  const partnersSpecial = [
    { img: R9 },
    { img: Cinno },
    { img: FoodGo },
    { img: WorldWine },
    { img: Premier },
    { img: RadioSound },
    { img: ImigM },
  ];

  const partnersForum = [
    { img: GreenEl },
    { img: Konsort },
    { img: Yavir },
    { img: TG },
    { img: Autotrans },
    { img: Ende },
    { img: Fibra },
  ];

  const getPartner = (img: string) => (
    <div key={img} className={classNames("df-center", styles.img)}>
      <img className="img" src={img} alt="img: Partner" />
    </div>
  );

  return (
    <div className={styles.rootBg}>
      <Container
        className={classNames("df-center fd-column", styles.container)}
        id="partners"
      >
        <Title text="ПАРТНЕРИ ФОРУМУ" />
        <div className={classNames("df-start fd-column", styles.content)}>
          <div className={classNames("df-start", styles.row)}>
            <div
              className={classNames(
                "df-center fd-column",
                styles.column,
                styles.pab,
              )}
            >
              <p className={styles.subtitle}>ОРГАНіЗАТОР </p>
              <div className={classNames("df-center", styles.img, styles.img1)}>
                <img className="img" src={PabLogo} alt="img: Організатор" />
              </div>
            </div>
            <div className={classNames("df-center fd-column", styles.column)}>
              <p className={styles.subtitle}>головний партнер</p>
              <div className={classNames("df-center", styles.img, styles.img1)}>
                <img className="img" src={UkrSibBank} alt="img: Main Partner" />
              </div>
            </div>
            <div className={classNames("df-center fd-column", styles.column)}>
              <p className={styles.subtitle}>інформаційний партнер</p>
              <div className={classNames("df-center", styles.img, styles.img1)}>
                <img className="img" src={MM} alt="img: Info Partner" />
              </div>
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={classNames("df-start fd-column", styles.section)}>
            <p className={classNames(styles.subtitle, styles.textLeft)}>
              партнери форуму
            </p>
            <div className={styles.rowWrapper}>
              <div className={classNames(styles.wrapper, styles.wrapper4)}>
                {partnersForum.map((item) => getPartner(item.img))}
              </div>
              <div className={styles.block}>
                <p className={styles.blockTitle}>
                  Продовжуємо підтримувати{"\n"}
                  Сили оборони україни.
                </p>
                <img src={Shieds} alt={"Сили оборони україни"} />
                <p className={styles.blockText}>
                  Усі залучені партнерські кошти підуть на потреби полтавських
                  війських бригад.
                </p>
              </div>
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={classNames("df-start fd-column", styles.section)}>
            <p className={classNames(styles.subtitle, styles.textLeft)}>
              Учасники ПАБу
            </p>
            <div className={styles.wrapper}>
              {partnersList.map((item) => getPartner(item.img))}
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={classNames("df-start fd-column", styles.section)}>
            <p className={classNames(styles.subtitle, styles.textLeft)}>
              Спеціальні партнери
            </p>
            <div className={styles.wrapper}>
              {partnersSpecial.map((item) => getPartner(item.img))}
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.lightDot}></div>
      <div className={styles.darkDot}></div>
      <img className={styles.sphere} src={Sphere} alt={"Levitation dot"} />
    </div>
  );
};
