import { Container } from "../Container";
import { useCallback, useEffect, useState } from "react";
import { ROUTES } from "../../routing/constants";
import { Logo, LogoSize } from "../Logo";
import { Button, ButtonType } from "../Button";
import classNames from "classnames/dedupe";
import styles from "./Header.module.sass";
import { Timer } from "../Timer";
import { END_DATA } from "../../constants/time";

const HeaderNavigation = [
  { name: "Про форум", link: `${ROUTES.main.createURL()}#forumInfo` },
  { name: "Квитки", link: `${ROUTES.main.createURL()}#tickets` },
  { name: "Партнери", link: `${ROUTES.main.createURL()}#partners` },
  { name: "Про ПАБ", link: `${ROUTES.main.createURL()}#pabInfo` },
];

export const Header = () => {
  const [showLightBg, setShowLightBg] = useState(false);
  const [showBg, setShowBg] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY >= 3) {
      setShowLightBg(true);
    } else {
      setShowLightBg(false);
    }
    if (window.scrollY >= window.outerHeight * 0.75) {
      setShowBg(true);
    } else {
      setShowBg(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={classNames(
          styles.header,
          showLightBg ? (showBg ? styles.bg : styles.lightBg) : "",
        )}
      >
        <Container className={classNames("df-between", styles.container)}>
          <a href={ROUTES.main.createURL()}>
            <Logo size={LogoSize.extraSmall} />
          </a>
          <nav className={classNames("df-center", styles.nav)}>
            <ul className={classNames("df-between", styles.list)}>
              {HeaderNavigation.map((item) => (
                <li className={styles.listItems} key={item.link + item.name}>
                  <a href={item.link}>{item.name}</a>
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.timerWrapper}>
            <Timer className={styles.timer} targetDate={END_DATA} />
          </div>
          <Button size={ButtonType.Small}></Button>
        </Container>
      </header>
    </>
  );
};
