import { FC } from "react";
import styles from "./Gallery.module.sass";
import { Container } from "../../../../components/Container";
import Img1 from "../../../../assets/img/gallery/1.jpg";
import Img2 from "../../../../assets/img/gallery/2.jpg";
import Img3 from "../../../../assets/img/gallery/3.jpg";
import Img4 from "../../../../assets/img/gallery/4.jpg";
import Img5 from "../../../../assets/img/gallery/5.jpeg";
import Img6 from "../../../../assets/img/gallery/6.jpg";
import Img7 from "../../../../assets/img/gallery/7.jpg";
import classNames from "classnames/dedupe";

export const Gallery: FC = () => {
  return (
    <Container>
      <div className={styles.root}>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img1)}>
          <img className="img" src={Img1} alt={"img: Gallery 1"} />
        </div>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img2)}>
          <img className="img" src={Img2} alt={"img: Gallery 2"} />
        </div>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img3)}>
          <img className="img" src={Img3} alt={"img: Gallery 3"} />
        </div>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img4)}>
          <img className="img" src={Img4} alt={"img: Gallery 4"} />
        </div>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img5)}>
          <img className="img" src={Img5} alt={"img: Gallery 5"} />
        </div>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img6)}>
          <img className="img" src={Img6} alt={"img: Gallery 6"} />
        </div>
        <div data-aos="zoom-in" className={classNames(styles.img, styles.img7)}>
          <img className="img" src={Img7} alt={"img: Gallery 7"} />
        </div>
      </div>
    </Container>
  );
};
