import { Banner } from "../components/Banner";
import { Timer } from "../components/Timer";
import { Numbers } from "../components/Numbers";
import { Info } from "../components/Info/Info";
import { Partners } from "../components/Partners";
import { Maps } from "../components/Maps";
import { ProPab } from "../components/ProPab";
import { Promotion } from "../components/Promotion";
import { Gallery } from "../components/Gallery";
import { END_DATA } from "../../../constants/time";
import Bg from "../../../assets/img/secondary_bg.png";
import Bg2 from "../../../assets/img/secondary_bg_mobile.png";
import styles from "./MainPage.module.sass";
import { Tickets } from "../components/Tickets";
import { Speakers } from "../components/Speakers";
import { Workshop } from "../components/Workshop";
import { ForumProgram } from "../components/ForumProgram";
import classNames from "classnames/dedupe";

export const MainPage = () => {
  return (
    <div className={styles.root}>
      <Banner />
      <Timer targetDate={END_DATA} />
      <div className={styles.bg}>
        <img className={classNames(styles.bgImg, styles.bgDt)} src={Bg} alt="bg" />
        <img className={classNames(styles.bgImg, styles.bgMb)} src={Bg2} alt="bg" />
        <Numbers />
        <Info />
        <Speakers />
        <Tickets />
        <ForumProgram />
        <Workshop />
        <Partners />
      </div>
      <Maps />
      <ProPab />
      <Promotion />
      <Gallery />
    </div>
  );
};
