import { FC, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper/modules";
import styles from "./Speakers.module.sass";
import classNames from "classnames/dedupe";
import Speaker1 from "../../../../assets/img/spikers/1.png";
import Speaker2 from "../../../../assets/img/spikers/2.png";
import Speaker3 from "../../../../assets/img/spikers/3.png";
import Speaker4 from "../../../../assets/img/spikers/4.png";
import Speaker5 from "../../../../assets/img/spikers/5.png";
import Speaker6 from "../../../../assets/img/spikers/6.png";
import Speaker7 from "../../../../assets/img/spikers/7.png";
import Speaker8 from "../../../../assets/img/spikers/8.png";
import Speaker9 from "../../../../assets/img/spikers/9.png";
import Speaker10 from "../../../../assets/img/spikers/10.png";
import Speaker11 from "../../../../assets/img/spikers/11.png";
import Speaker12 from "../../../../assets/img/spikers/12.png";
import Speaker13 from "../../../../assets/img/spikers/13.png";
import Speaker14 from "../../../../assets/img/spikers/14.png";
import Speaker15 from "../../../../assets/img/spikers/15.png";
import Logo1 from "../../../../assets/img/spikers_logo/workUa.png";
import Logo2 from "../../../../assets/img/spikers_logo/16363.svg";
import Logo3 from "../../../../assets/img/spikers_logo/np.png";
import Logo4 from "../../../../assets/img/spikers_logo/ins_fr.png";
import Logo5 from "../../../../assets/img/spikers_logo/hugs.svg";
import Logo6 from "../../../../assets/img/spikers_logo/digicode.svg";
import Logo7 from "../../../../assets/img/spikers_logo/uabe.png";
import Logo8 from "../../../../assets/img/spikers_logo/aimbulance.svg";
import Logo9 from "../../../../assets/img/spikers_logo/mp.svg";
import Logo10 from "../../../../assets/img/spikers_logo/avrora.svg";
import Logo11 from "../../../../assets/img/spikers_logo/konsort.svg";
import Logo12 from "../../../../assets/img/spikers_logo/mm.svg";
import Logo13 from "../../../../assets/img/spikers_logo/zsu.svg";
import "swiper/css";

interface ISpeakerCard {
  name: string;
  text: string;
  img: string;
  logo: string;
  logoWidth: number;
  className?: string;
}

const speakers: ISpeakerCard[] = [
  {
    name: "Артур\nМіхно",
    text: "підприємець, СЕО та співзасновник Work.ua, сайту з пошуку роботи #1 в Україні.",
    img: Speaker1,
    logo: Logo1,
    logoWidth: 153,
  },
  {
    name: "Світлана\nСтреляна",
    text: 'підприємиця, співвласниця та членкиня Наглядової ради ТДВ “АТП 16363”. Засновниця проєкту "Сестри доріг" – залучення жінок у далекобійниці.',
    img: Speaker2,
    logo: Logo2,
    logoWidth: 92,
  },
  {
    name: "Володимир\nПопершнюк",
    text: "підприємець, співвласник групи компаній NOVA (Нова пошта)",
    img: Speaker3,
    logo: Logo3,
    logoWidth: 158,
  },
  {
    name: "Євген Глібовицький",
    text: "генеральний директор Інституту Фронтиру. Український журналіст, експерт з довготермінових стратегій.",
    img: Speaker4,
    logo: Logo4,
    logoWidth: 143,
  },
  {
    name: "Ерік Найман",
    text: "Засновник інвестиційного радника HUGʼS.\nУкраїнський економіст, фінансовий аналітик, автор\n 6-ти книг з інвестування.",
    img: Speaker5,
    logo: Logo5,
    logoWidth: 156,
  },
  {
    name: "Ігор\nШейко",
    text: "Підприємець та співвласник міжнародної IT-компанії Digicode з надання послуг по розробці програмного забезпечення, учасник Полтавської асоціації бізнесу.",
    img: Speaker6,
    logo: Logo6,
    logoWidth: 107,
  },
  {
    name: "Станіслав\nІгнатьєв",
    text: "Голова Ради Української асоціації відновлюваної енергетики. Доктор технічних наук.",
    img: Speaker7,
    logo: Logo7,
    logoWidth: 192,
  },
  {
    name: "Роман\nГавриш",
    text: "СЕО та співзасновник нейротехнологічного стартапу Anima.ua, власник маркетингової агенції Aimbulance, ад’юнкт професор Києво-Могилянської Бізнес-Школи, викладач бізнес-школи УКУ.",
    img: Speaker8,
    logo: Logo8,
    logoWidth: 197,
  },
  {
    name: "Михайло\nПацан",
    text: "Підприємець, інвестор, керуючий активами з 18-річним досвідом, експерт з міжнародних фінансових ринків та WEB3 технологій.",
    img: Speaker9,
    logo: Logo9,
    logoWidth: 76,
  },
  {
    name: "Марія\nПанченко",
    text: "Директорка з трансформації компанії Аврора Мультимаркет",
    img: Speaker10,
    logo: Logo10,
    logoWidth: 155,
  },
  {
    name: "Олексій\nВиноградов",
    text: "Віцепрезидент Бізнес-школи МІМ. Доцент кафедри бізнес-адміністрування, кандидат економічних наук",
    img: Speaker14,
    logo: Logo12,
    logoWidth: 75,
  },
  {
    name: "Валерій\nМащенко",
    text: 'Полковник, командир 225 зенітно-ракетного полку Повітряного командування "Схід". Діючий бойовий командир',
    img: Speaker15,
    logo: Logo13,
    logoWidth: 70,
  },
  {
    name: "Віталій\nДмитрієв",
    text: "Власник, голова Наглядової Ради Асоціації KONSORT",
    img: Speaker11,
    logo: Logo11,
    logoWidth: 145,
    className: styles.full,
  },
  {
    name: "Ганна\nПриходченко",
    text: "Лідерка напрямку рекрутингу компанії Аврора Мультимаркет",
    img: Speaker12,
    logo: Logo10,
    logoWidth: 155,
    className: styles.full,
  },
  {
    name: "Олександр\nКолесніков",
    text: "Технічний керівник ІТ\nкомпанії Digicode",
    img: Speaker13,
    logo: Logo6,
    logoWidth: 107,
    className: styles.full,
  },
];

export const Speakers: FC = () => {
  const getSpeakerCard = useCallback(
    (speaker: ISpeakerCard, index: number) => (
      <SwiperSlide key={index} className={styles.card}>
        <div className={classNames(styles.cardCommon, styles.cardFront)}>
          <p className={styles.cardName}>{speaker.name}</p>
          <img
            className={classNames(styles.cardImage, speaker?.className || "")}
            src={speaker.img}
            alt={`img: Speaker ${index}`}
          />
        </div>
        <div className={classNames(styles.cardCommon, styles.cardBack)}>
          <div className={styles.content}>
            <strong className={styles.name}>{speaker.name}</strong>
            <p className={styles.text}>{speaker.text}</p>
          </div>
          <img
            style={{ width: speaker.logoWidth }}
            className={styles.logo}
            src={speaker.logo}
            alt="logo: Work.ua"
          />
        </div>
      </SwiperSlide>
    ),
    [],
  );

  return (
      <div className={classNames("fd-column", styles.root)}>
        <div className={styles.darkDot}></div>
        <h2 className={styles.title}>Спікери</h2>
        <Swiper
            className={styles.swiper}
            slidesPerView={1}
            modules={[Mousewheel, Autoplay]}
            spaceBetween={30}
            mousewheel={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              500: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              580: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1050: {
                slidesPerView: 4,
                spaceBetween: 29,
              },
              1300: {
                slidesPerView: 5,
                spaceBetween: 29,
              },
              1540: {
                slidesPerView: 6,
                spaceBetween: 29,
              },
            }}
        >
          {speakers?.map((item, index) => getSpeakerCard(item, index))}
        </Swiper>
      </div>
  );
};
