import { FC } from "react";
import { Container } from "../../../../components/Container";
import { Title } from "../../../../components/Title";
import styles from "./ForumProgram.module.sass";
import classNames from "classnames/dedupe";
import Speaker1 from "../../../../assets/img/program/1.png";
import Speaker2 from "../../../../assets/img/program/2.png";
import Speaker3 from "../../../../assets/img/program/3.png";
import Speaker4 from "../../../../assets/img/program/4.png";
import Speaker5 from "../../../../assets/img/program/5.png";
import Speaker6 from "../../../../assets/img/program/6.png";
import Speaker7 from "../../../../assets/img/program/7.png";
import Speaker8 from "../../../../assets/img/program/8.png";
import Speaker9 from "../../../../assets/img/program/9.png";
import Speaker10 from "../../../../assets/img/program/10.png";
import Speaker11 from "../../../../assets/img/program/11.png";
import Speaker12 from "../../../../assets/img/program/12.png";

interface IProgram {
  startAt: string;
  endAt: string;
  title: string;
  topic: string;
  img?: string;
  className?: string;
}

interface ISubProgram {
  startAt: string;
  endAt: string;
  title: string;
  className?: string;
}

const programBlock1: IProgram[] = [
  {
    startAt: "11:00",
    endAt: "11:20",
    title: "Ірина Бессарабова, \nЮлія Кременчуцька",
    topic: "Вітальні слова",
    className: styles.wrapperFull,
  },
  {
    startAt: "11:20",
    endAt: "11:50",
    title: "Олексій Виноградов",
    topic: "Сучасна бізнес-освіта: все, що потрібно знати про неї підприємцю",
    img: Speaker11,
  },
  {
    startAt: "11:50",
    endAt: "12:20",
    title: "Світлана Стреляна",
    topic: "Демографічна криза. Брак людей чи брак компетенцій?",
    img: Speaker2,
  },
  {
    startAt: "12:20",
    endAt: "12:50",
    title: "Артур Міхно",
    topic: "Друга навігація. Інвестиції у світогляд",
    img: Speaker3,
  },
  {
    startAt: "12:50",
    endAt: "13:20",
    title: "Роман Гавриш",
    topic:
      "Синхронізація розумів: як нейронауки дозволяють зрозуміти себе та інших. (онлайн)",
    img: Speaker8,
  },
  {
    startAt: "13:20",
    endAt: "13:50",
    title: "Ігор Шейко",
    topic:
      "Практичне використання ШІ. Зростання ефективності бізнесу на фоні демографічної кризи.",
    img: Speaker6,
  },
  {
    startAt: "13:50",
    endAt: "14:00",
    title: "Валерій Мащенко",
    topic:
      "У чому наша сила, як нам перемагати та чому армія та бізнес подібні?",
    img: Speaker12,
  },
];

const programBlock2: IProgram[] = [
  {
    startAt: "15:10",
    endAt: "15:40",
    title: "Володимир Поперешнюк",
    topic: "Як знаходити драйвер розвитку в кризах. ",
    img: Speaker4,
  },
  {
    startAt: "15:40",
    endAt: "16:10",
    title: "Ерік Найман",
    topic:
      "Бізнес та економіка воєнного часу. Три сценарії розвитку економічного та соціального майбутнього України. ",
    img: Speaker5,
  },
  {
    startAt: "16:10",
    endAt: "16:40",
    title: "Марія Панченко",
    topic:
      "Мережа мультимаркетів Аврора: глобалізація, як виклик та можливості. Доступ до нових ринків, технологій та талантів.",
    img: Speaker10,
  },
  {
    startAt: "16:40",
    endAt: "17:10",
    title: "Станіслав Ігнатьєв",
    topic:
      "Стан енергетичної системи в Україні: Нова енергетична карта. Енергосталість бізнесу.",
    img: Speaker7,
  },
  {
    startAt: "17:10",
    endAt: "17:40",
    title: "Михайло Пацан",
    topic:
      "Інвестування та криптовалюти: біткоін по $100,000 — міф чи реальність?",
    img: Speaker9,
  },
  {
    startAt: "17:40",
    endAt: "18:10",
    title: "Євген Глібовицький",
    topic: "У пошуках багатства: погляд крізь демографію, безпеку та культуру.",
    img: Speaker1,
  },
];

const SubProgram1: ISubProgram[] = [
  {
    startAt: "14:00",
    endAt: "15:00",
    title: "Обід",
  },
  {
    startAt: "15:00",
    endAt: "15:10",
    title: "розіграш призів від партнерів",
    className: styles.orange,
  },
];

const SubProgram2: ISubProgram[] = [
  {
    startAt: "18:00",
    endAt: "18:30",
    title: "Перформанс",
  },
  {
    startAt: "18:25",
    endAt: "18:30",
    title: "Завершення, фото",
    className: styles.orange,
  },
];

export const ForumProgram: FC = () => {
  const ProgramNode: FC<IProgram> = (program) => (
    <div
      data-aos="zoom-out"
      className={classNames(styles.row, program.className || "")}
    >
      <div className={classNames("df-center fd-column", styles.head)}>
        <p className={classNames("df-center fd-column")}>
          <span className={styles.timeText}>Початок</span>
          <strong className={styles.time}>{program?.startAt}</strong>
        </p>
        <hr className={styles.line} />
        <p className={classNames("df-center fd-column", styles.columnReverse)}>
          <strong className={styles.time}>{program.endAt}</strong>
          <span className={styles.timeText}>завершення</span>
        </p>
      </div>
      <div className={styles.content}>
        <div className={classNames("df-center fd-column", styles.wrapper)}>
          <h3 className={styles.title}>{program.title}</h3>
          <hr className={styles.lightLine} />
          <h4 className={styles.topic}>{program.topic}</h4>
        </div>
        {program?.img && (
          <div className={styles.imgWrapper}>
            <img src={program.img} alt={`Speaker: ${program.title}`} />
          </div>
        )}
      </div>
    </div>
  );

  const SubProgramNode: FC<ISubProgram> = (program) => (
    <div data-aos="zoom-out" className={classNames("df-center", styles.row2)}>
      <div className={classNames("df-center", styles.head2)}>
        <p className={classNames("df-center", styles.column)}>
          <span className={styles.timeText}>Початок</span>
          <strong className={styles.time}>{program?.startAt}</strong>
        </p>
        <hr className={styles.line2} />
        <p className={classNames("df-center", styles.column)}>
          <strong className={styles.time}>{program.endAt}</strong>
          <span className={styles.timeText}>завершення</span>
        </p>
      </div>
      <div
        className={classNames(
          "df-center",
          styles.tag,
          program?.className || "",
        )}
      >
        <h3 className={styles.tagText}>{program.title}</h3>
      </div>
    </div>
  );

  return (
    <Container className={classNames("df-center fd-column", styles.root)}>
      <Title text="Програма форуму" />
      <div className={classNames("fd-column", styles.container)}>
        {programBlock1.map((program) => (
          <ProgramNode key={program.topic} {...program} />
        ))}
        {SubProgram1.map((program) => (
          <SubProgramNode key={program.title} {...program} />
        ))}
        {programBlock2.map((program) => (
          <ProgramNode key={program.topic} {...program} />
        ))}
        {SubProgram2.map((program) => (
          <SubProgramNode key={program.title} {...program} />
        ))}
      </div>
    </Container>
  );
};
